@tailwind base;
@tailwind components;
@tailwind utilities;

/* CSS Reset for mobile devices */
* {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  touch-action: manipulation;
}

/* Prevent text size adjustment on orientation change */
html {
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

:root {
  --color-primary-blue: #008ffe;
  --color-primary-blue-hover: #0070cc;
  --color-primary-orange: #fe5d00;
  --color-primary-orange-hover: #cc4a00;
  --color-primary-yellow: #fedc00;
  --color-primary-yellow-hover: #ccb000;
}

/* Base styles with mobile considerations */
body {
  @apply bg-gray-50 text-gray-900 dark:bg-dark-background dark:text-dark-text;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: none;
  padding: var(--sat) var(--sar) var(--sab) var(--sal);
}

/* Mobile-friendly scrolling */
.scroll-container {
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
}

/* Improve touch targets for mobile */
button, 
a, 
input[type="submit"],
input[type="button"],
[role="button"] {
  @apply min-h-[44px] min-w-[44px];
  touch-action: manipulation;
}

/* Adjust font sizes for mobile */
@media (max-width: 640px) {
  html {
    font-size: 14px;
  }
  h1 { @apply text-2xl; }
  h2 { @apply text-xl; }
  h3 { @apply text-lg; }
  body { @apply text-sm; }
}

/* Dark mode styles */
.dark .bg-white {
  @apply bg-dark-secondary;
}

.bg-gray-100 {
  @apply bg-light-secondary dark:bg-dark-secondary;
}

.dark .text-gray-600 {
  @apply text-gray-400;
}

.dark .border-gray-300 {
  @apply border-gray-700;
}

.dark .hover\:bg-gray-100:hover {
  @apply hover:bg-gray-700;
}

/* Button styles with mobile optimizations */
.btn {
  @apply px-4 py-2 rounded font-semibold text-sm text-white shadow-sm transition duration-150;
  touch-action: manipulation;
}

/* Mobile-friendly hover states */
@media (hover: hover) {
  .btn:hover {
    @apply opacity-90;
  }
}

/* Button styles using primary colors */
.btn-primary {
  @apply bg-primary-blue text-white;
}

.btn-primary:hover {
  background-color: var(--color-primary-blue-hover);
}

.btn-orange {
  @apply bg-primary-orange text-white;
}

.btn-orange:hover {
  background-color: var(--color-primary-orange-hover);
}

.btn-yellow {
  @apply bg-primary-yellow text-black;
}

.btn-yellow:hover {
  background-color: var(--color-primary-yellow-hover);
}

.btn-secondary {
  @apply bg-gray-500 hover:bg-gray-600 dark:bg-gray-600 dark:hover:bg-gray-700;
}

/* Card styles */
.card {
  @apply bg-white rounded-lg shadow-md p-6 dark:bg-dark-secondary;
}

/* Quiz styles */
.answer-option {
  @apply border border-gray-400 dark:border-gray-500;
}
/* Light mode */
.answer-option.no-confidence-selected {
  @apply bg-blue-400 text-white border-transparent;
}

.answer-option.confidence-selected {
  @apply bg-primary-blue text-white border-transparent;
}

/* Dark mode */
.dark .answer-option.no-confidence-selected {
  @apply bg-blue-500 text-white border-transparent;
}

.dark .answer-option.confidence-selected {
  @apply bg-primary-blue text-white border-transparent;
}

/* Hover states */
.answer-option.no-confidence-selected:hover {
  @apply bg-blue-300;
}

.answer-option.confidence-selected:hover {
  @apply bg-blue-400;
}

.dark .answer-option.no-confidence-selected:hover {
  @apply bg-primary-blue;
}

.dark .answer-option.confidence-selected:hover {
  @apply bg-primary-blue;
}

/* New styles for confidence buttons */
.confidence-button {
  @apply border border-gray-300 dark:border-gray-600;
}

.confidence-button.selected {
  @apply border-transparent;
}

/* Correct and incorrect states */
.answer-option.correct,
.answer-option.incorrect {
  @apply border-transparent;
}

/* Quiz summary styles */
.quiz-summary .answer-option {
  @apply cursor-default;
}

.quiz-summary .answer-option:hover {
  @apply bg-transparent dark:bg-transparent;
}

/* Table-based questions */
.quiz-content table td [data-choice] {
  @apply cursor-pointer transition-colors duration-200;
}

.quiz-content table td [data-choice]:hover:not(.selected):not(.correct):not(.incorrect) {
  @apply bg-gray-100 dark:bg-gray-700;
}

.quiz-content table td.selected [data-choice] {
  @apply bg-primary-blue text-white dark:bg-primary-blue dark:text-white;
}

.quiz-content table td.correct [data-choice] {
  @apply bg-green-500 text-white dark:bg-green-700 dark:text-white;
}

.quiz-content table td.incorrect [data-choice] {
  @apply bg-red-500 text-white dark:bg-red-700 dark:text-white;
}

.quiz-content td [data-choice] {
  @apply cursor-pointer transition-colors duration-200;
}

.quiz-content td [data-choice]:hover {
  @apply bg-gray-100 dark:bg-gray-700;
}

.quiz-content td.selected [data-choice] {
  @apply bg-blue-100 dark:bg-blue-800;
}

.quiz-content table {
  @apply border-collapse border border-gray-300 my-4 dark:border-gray-600;
}

.quiz-content th, .quiz-content td {
  @apply border border-gray-300 p-2 dark:border-gray-600;
}

.quiz-content ul, .quiz-content ol {
  @apply list-disc list-inside my-2;
}

/* Quiz content styles */
.quiz-content p {
  @apply mb-6;
}

.quiz-content ol {
  @apply list-decimal list-inside;
}

.quiz-content li {
  @apply mb-2;
}

/* Roman numeral list style */
.quiz-content ol.upper-roman {
  @apply list-none pl-0;
  counter-reset: roman-counter;
}

.quiz-content ol.upper-roman > li {
  @apply pl-8 relative;
  counter-increment: roman-counter;
}

.quiz-content ol.upper-roman > li::before {
  content: counter(roman-counter, upper-roman) ". ";
  @apply absolute left-0 font-bold;
}

/* Multiple choice options */
.quiz-content ol.multiple-choice {
  @apply list-none pl-0 mt-4;
}

.quiz-content ol.multiple-choice > li {
  @apply pl-8 relative mb-2;
}

.quiz-content ol.multiple-choice > li::before {
  content: attr(data-option) ". ";
  @apply absolute left-0 font-bold;
}

/* Table-based multiple-choice questions */
.quiz-content table.table-borderless {
  @apply border-collapse my-4;
}

.quiz-content table.table-borderless th,
.quiz-content table.table-borderless td {
  @apply border-0 p-2;
}

.quiz-content table.table-borderless thead th {
  @apply font-bold;
}

.quiz-content table.table-borderless tbody td {
  @apply align-top;
}

.quiz-content table.table-borderless .question-choices-multi {
  @apply list-none p-0 m-0;
}

.quiz-content [data-choice] {
  @apply cursor-pointer transition-colors duration-200;
}

.quiz-content [data-choice]:hover {
  @apply bg-gray-100 dark:bg-gray-700;
}

.quiz-content li.selected [data-choice],
.quiz-content [data-choice].selected {
  @apply bg-blue-100 dark:bg-blue-800;
}

.quiz-content li.correct [data-choice],
.quiz-content [data-choice].correct {
  @apply bg-green-200 dark:bg-green-800;
}

.quiz-content li.incorrect [data-choice],
.quiz-content [data-choice].incorrect {
  @apply bg-red-200 dark:bg-red-800;
}

.quiz-content table {
  @apply border-collapse my-4 w-full;
}

.quiz-content table th,
.quiz-content table td {
  @apply border border-gray-300 p-2 dark:border-gray-600;
}

.quiz-content table thead th {
  @apply font-bold;
}

.quiz-content table tbody td {
  @apply align-top;
}

.quiz-content table td [data-choice] {
  @apply cursor-pointer border border-black p-2 inline-block w-full h-full dark:border-gray-400;
}

.quiz-content table td.selected {
  background-color: var(--color-primary-blue);
}

.quiz-content table td.correct {
  @apply bg-green-200 dark:bg-green-800;
}

.quiz-content table td.incorrect {
  @apply bg-red-200 dark:bg-red-800;
}

.quiz-content table td [data-choice] {
  @apply cursor-default;
}

.quiz-content table td.selected [data-choice] {
  @apply font-bold;
}

/* Quiz summary styles */
.quiz-summary .option {
  @apply p-2 rounded mb-2;
}

.quiz-summary .option.correct {
  @apply bg-green-200 dark:bg-green-800;
}

.quiz-summary .option.incorrect {
  @apply bg-red-200 dark:bg-red-800;
}

.quiz-summary .option.selected {
  @apply font-bold;
}

.quiz-content .user-answer {
  @apply bg-yellow-200 dark:bg-yellow-800;
}

.quiz-content .correct-answer {
  @apply bg-green-200 dark:bg-green-800;
}

.quiz-content .incorrect-answer {
  @apply bg-red-200 dark:bg-red-800;
}

.quiz-content table td.user-answer,
.quiz-content table td.correct-answer,
.quiz-content table td.incorrect-answer {
  position: relative;
}

.quiz-content table td.user-answer::after,
.quiz-content table td.correct-answer::after,
.quiz-content table td.incorrect-answer::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.3;
}

.quiz-content table td.user-answer::after {
  @apply bg-yellow-200 dark:bg-yellow-800;
}

.quiz-content table td.correct-answer::after {
  @apply bg-green-200 dark:bg-green-800;
}

.quiz-content table td.incorrect-answer::after {
  @apply bg-red-200 dark:bg-red-800;
}

/* Loading spinner for quiz summary */
.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid var(--color-primary-blue);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Flashcard styles */

.flashcard-container {
  perspective: 1000px;
}

.flashcard {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flashcard.front {
  transform: rotateX(0deg);
}

.flashcard.back {
  transform: rotateX(180deg);
}

.flashcard-content {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  background-size: 20px 20px;
  padding: 5%;
  overflow-y: auto;
}

.flashcard.front .flashcard-content {
  display: flex;
  align-items: left;
  justify-content: left;
  text-align: left;
}

.flashcard.back .flashcard-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  transform: rotateX(180deg);
}

.flashcard-side-indicator {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 0.75rem;
  font-weight: 600;
  color: #9ca3af;
  z-index: 10;
}

.flashcard.back .flashcard-side-indicator {
  transform: rotateX(180deg);
}

.flashcard-html-content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow-y: auto;
}

.flashcard.back .flashcard-html-content {
  transform: rotate(360deg);
}

.flipping .flashcard {
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flashcard-html-content {
  font-size: 1rem;
  line-height: 1.5;
}

.flashcard-html-content p {
  margin-bottom: 1rem;
}

.flashcard-html-content h1,
.flashcard-html-content h2,
.flashcard-html-content h3,
.flashcard-html-content h4,
.flashcard-html-content h5,
.flashcard-html-content h6 {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  font-weight: bold;
}

.quiz-content ul,
.quiz-content ol,
.flashcard-html-content ul,
.flashcard-html-content ol {
  list-style: revert !important;
  margin: revert !important;
  padding: revert !important;
}

/* Specific overrides for flashcard content */
.flashcard-html-content ul {
  list-style-type: disc !important;
  margin-left: 1.5em !important;
  padding-left: 1em !important;
}

.flashcard-html-content ol {
  list-style-type: decimal !important;
  margin-left: 1.5em !important;
  padding-left: 1em !important;
}

.flashcard-html-content li {
  display: list-item !important;
  margin-bottom: 0.5em !important;
}

.flashcard-html-content a {
  color: var(--color-primary-blue);
  text-decoration: underline;
}

.dark .flashcard-html-content a {
  color: var(--color-primary-blue);
}

.flashcard-html-content img {
  max-width: 100%;
  height: auto;
  margin-bottom: 1rem;
}

.flashcard-html-content table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1rem;
}

.flashcard-html-content th,
.flashcard-html-content td {
  border: 1px solid #ccc;
  padding: 0.5rem;
}

.dark .flashcard-html-content th,
.dark .flashcard-html-content td {
  border-color: #4a5568;
}

.flashcard-html-content th {
  background-color: #f3f4f6;
  font-weight: bold;
}

.dark .flashcard-html-content th {
  background-color: #2d3748;
}

.flashcard-html-content code {
  background-color: #f3f4f6;
  padding: 0.2rem 0.4rem;
  border-radius: 0.25rem;
  font-family: monospace;
}

.dark .flashcard-html-content code {
  background-color: #2d3748;
}

.flashcard-html-content pre {
  background-color: #f3f4f6;
  padding: 1rem;
  border-radius: 0.25rem;
  overflow-x: auto;
  margin-bottom: 1rem;
}

.dark .flashcard-html-content pre {
  background-color: #2d3748;
}

.flashcard-html-content blockquote {
  border-left: 4px solid #e5e7eb;
  padding-left: 1rem;
  margin-left: 0;
  margin-bottom: 1rem;
  font-style: italic;
}

.dark .flashcard-html-content blockquote {
  border-left-color: #4a5568;
}

/*SIDEBAR */

/* SIDEBAR */
.sidebar {
  transition: width 0.3s;
}



/*Quiz Customizer */

.quiz-customizer {
  z-index: 40; /* Ensure this is less than the sidebar chevron button */
}

.keslerq-filter-scroll {
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

.keslerq-filter-scroll::-webkit-scrollbar {
  display: none; /* WebKit */
}

@media (max-width: 767px) {
  .ml-20 {
    margin-left: 0;
  }
}



    /*TABLE TEMPLATES */

    table { margin-bottom: 18px; }

    table.details-panel th:first-child { /*removes default Tenant CSS class */
      width: unset;
    }

    div.table-title {
      h4, h5, h6, p {
        margin-bottom: 0;
        font-weight: bold;
      }
    }

    div.table-footnote {
      p, span, em, strong, u {
        margin-top: 0;
        margin-bottom: 18px;
        font-size: 12px;
        a {
          font-size: 12px;
        }
      }
    }

    /* TABLE STYLES */

    .table-style1 { /* Table with Header Background */
      margin: .5em 0 1.5em 0;
      border: 1px solid #4E4F55;/*Primary Color*/
      width: 100%;
      & > thead > tr > th {
        padding: 8px;
        background: #4E4F55;/*Primary Color*/
        border: 1px solid #FFFFFF;
        text-align: center;
        color: #FFFFFF;
        font-weight: bold;
        p, em, span, strong, u {
          color: #FFFFFF;
          font-weight: bold;
        }
        &:first-child { /*removes default Tenant CSS class */
          width: unset;
        }
      }
      & > tbody > tr > td {
        background-color: #ffffff;
        padding: 8px;
        border: 1px solid #4E4F55;/*Primary Color*/
      }
    }

    .table-style2 { /* Table with two thick boarders at the top and bottom */
      margin: .5em 0 1.5em 0;
      border: 1px solid #4E4F55;/*Primary Color*/
      border-bottom: 6px solid #4E4F55;/*Primary Color*/
      border-top: 6px solid #4E4F55;/*Primary Color*/
      & > thead > tr > th {
        border-bottom: 3px solid #4E4F55;/*Primary Color*/
        border-left: 1px solid #4E4F55;/*Primary Color*/
        padding: 8px;
        text-align: center;
        p, em, span, strong, u {
          font-weight: bold;
        }
      }
      th:first-child { /*removes default Tenant CSS class */
        width: unset;
      }
      & > tbody > tr > td {
        background-color: #ffffff;
        padding: 8px;
        border-left: 1px solid #4E4F55;/*Primary Color*/
        border-top: 1px solid #4E4F55;/*Primary Color*/
      }
    }

    .table-style3 { /* Table without inside Borders */
      margin: .5em 0 1.5em 0;
      border: 1px solid #4E4F55;/*Primary Color*/
      & > thead > tr > th {
        padding: 8px;
        background: #4E4F55;/*Primary Color*/
        text-align: center;
        p, em, span, strong, u {
          color: #FFFFFF;
          font-weight: bold;
        }
      }
      th:first-child { /*removes default Tenant CSS class */
        width: unset;
      }
      & > tbody > tr > td {
        background-color: #ffffff;
        padding: 8px;
        border-top: 1px solid #4E4F55;/*Primary Color*/
      }
    }

    .table-style4 { /* Table with two thick boarders at the top and bottom without middle borders */
      margin: .5em 0 1.5em 0;
      border: 1px solid #4E4F55;/*Primary Color*/
      border-bottom: 6px solid #4E4F55;/*Primary Color*/
      border-top: 6px solid #4E4F55;/*Primary Color*/
      & > thead > tr > th {
        border-bottom: 3px solid #4E4F55;/*Primary Color*/
        padding: 8px;
        text-align: center;
        p, em, span, strong, u {
          font-weight: bold;
        }
      }
      th:first-child { /*removes default Tenant CSS class */
        width: unset;
      }
      & > tbody > tr > td {
        background-color: #ffffff;
        padding: 8px;
        border-top: 1px solid #4E4F55;/*Primary Color*/
      }
    }

    .table-style5 { /* Simple Border Table */
      margin: .5em 0 1.5em 0;
      border: 1px solid #4E4F55;/*Primary Color*/
      & > thead > tr > th {
        border: 1px solid #4E4F55;/*Primary Color*/
        padding: 8px;
        text-align: center;
        p, em, span, strong, u {
            font-weight: bold;
        }
      }
      th:first-child { /*removes default Tenant CSS class */
        width: unset;
      }
      & > tbody > tr > td {
        background-color: #ffffff;
        padding: 8px;
        border: 1px solid #4E4F55;/*Primary Color*/
      }
    }

    .table-style6 { /* Simple Border Table without inside borders*/
      margin: .5em 0 1.5em 0;
      border: 1px solid #4E4F55;/*Primary Color*/
      & > thead > tr > th {
        border-bottom: 1px solid #4E4F55;/*Primary Color*/
        padding: 8px;
        text-align: center;
        p, em, span, strong, u {
          font-weight: bold;
        }
      }
      th:first-child { /*removes default Tenant CSS class */
        width: unset;
      }
      & > tbody > tr > td {
        background-color: #ffffff;
        padding: 8px;
      }
    }
    /* END OF TABLE STYLES */

    /* LIST STYLES */
    ul.square > li {
      list-style-type: square;
      list-style-position: outside;
      margin: 0 0 0 24px;
    }

    ul.disc > li {
      list-style-type: disc;
      list-style-position: outside;
      margin: 0 0 0 24px;
    }

    ul.circle > li {
      list-style-type: circle;
      list-style-position: outside;
      margin: 0 0 0 24px;
    }
    /* END OF LIST STYLES */

    /* BOXES STYLES*/

    div.callout-box {
      border-top: 1px solid black;
      border-bottom: 1px solid black;
      border-left: 6px solid #4E4F55;
      border-right: 6px solid #4E4F55;
      padding-right: .5em;
      padding-bottom: .5em;
      padding-left: .5em;
      padding-top: .2em;
      margin-bottom: 25px;
      & > h5 {
        background: #4E4F55;
          & > strong {
            color: white;
            padding-left: .5em;
          }
        }
      }


    div.case-study {
      border-left: 1px solid black;
      border-right: 1px solid black;
      padding-right: .5em;
      padding-bottom: .5em;
      padding-left: .5em;
      padding-top: .2em;
      & > h5 {
        background: #4E4F55;
        & > strong {
          color: white;
          padding-left: .5em;
        }
      }
      & > p {
        font-weight: normal;
      }
    }
    /* END OF BOXES STYLES */

    /* Hamburger button styles */
.hamburger-btn {
  background-color: transparent !important;
  border: none !important;
  padding: 0 !important;
}

.hamburger-btn:hover {
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.dark .hamburger-btn:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
}


/* Mobile-specific scrollbar styles */
.mobile-scroll {
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.mobile-scroll::-webkit-scrollbar {
  display: none;
}

/* Mobile navigation adjustments */
.mobile-nav {
  @apply fixed bottom-0 left-0 right-0;
  padding-bottom: var(--sab);
}

/* Mobile-friendly modal styles */
.mobile-modal {
  @apply fixed inset-0 z-50;
  padding: var(--sat) var(--sar) var(--sab) var(--sal);
}

/* Mobile-friendly table styles */
@media (max-width: 640px) {
  table {
    @apply block w-full overflow-x-auto;
    -webkit-overflow-scrolling: touch;
  }
}

/* Mobile-friendly form elements */
input[type="text"],
input[type="email"],
input[type="password"],
textarea {
  @apply w-full px-4 py-2;
  font-size: 16px !important;
}

/* Prevent text selection on interactive elements */
.no-select {
  -webkit-user-select: none;
  user-select: none;
}

/* Add momentum scrolling to containers */
.momentum-scroll {
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
}
